import * as contentful from "contentful";
import {
  FETCH_BLOG_POSTS_STARTED,
  FETCH_BLOG_POSTS_SUCCESS,
  FETCH_BLOG_POSTS_FAILURE,
  FETCH_SINGLE_BLOG_POST_STARTED,
  FETCH_SINGLE_BLOG_POST_SUCCESS,
  FETCH_SINGLE_BLOG_POST_FAILURE
} from "./types";

const client = contentful.createClient({
  space: "5gd2rpq2t2da",
  accessToken: "fI8XtETZXMw7ZAeNJEyyEGiPx6P5qwP5HzfvtuHKFxQ"
});

export const fetchBlogPosts = () => async dispatch => {
  try {
    dispatch({ type: FETCH_BLOG_POSTS_STARTED });
    let { items } = await client.getEntries({ content_type: "blogPost" });

    dispatch({ type: FETCH_BLOG_POSTS_SUCCESS, payload: items });
  } catch (error) {
    dispatch({
      type: FETCH_BLOG_POSTS_FAILURE,
      payload: error
    });
  }
};

export const fetchSingleBlogPost = slug => async dispatch => {
  try {
    dispatch({ type: FETCH_SINGLE_BLOG_POST_STARTED });
    let { items } = await client.getEntries({
      content_type: "blogPost",
      "fields.slug": slug
    });
    dispatch({ type: FETCH_SINGLE_BLOG_POST_SUCCESS, payload: items[0] });
  } catch (error) {
    dispatch({
      type: FETCH_SINGLE_BLOG_POST_FAILURE,
      payload: error
    });
  }
};
