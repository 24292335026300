import React from "react";
import Markdown from "markdown-to-jsx";
import { connect } from "react-redux";
import { format } from "date-fns";
import isEmpty from "lodash.isempty";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Icon } from "antd";
import { fetchSingleBlogPost } from "../../actions";

import { YCombinatorIcon, FacebookIcon, RedditIcon } from "../../img/icons";

class BlogPost extends React.Component {
  componentDidMount() {
    this.props.fetchSingleBlogPost(this.props.match.params.id);
  }
  render() {
    console.log(this.props);
    const { singlePost } = this.props;
    if (isEmpty(singlePost.data)) {
      return null;
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>{singlePost.data.fields.title}</title>
          <meta name="title" content={singlePost.data.fields.title} />
          <meta
            name="description"
            content={singlePost.data.fields.description}
          />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`https://notifime.app/blog/${singlePost.data.fields.slug}`}
          />
          <meta property="og:title" content={singlePost.data.fields.title} />
          <meta
            property="og:description"
            content={singlePost.data.fields.description}
          />
          <meta
            property="og:image"
            content={singlePost.data.fields.heroImage.fields.file.url}
          />

          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`https://notifime.app/blog/${singlePost.data.fields.slug}`}
          />
          <meta
            property="twitter:title"
            content={singlePost.data.fields.title}
          />
          <meta
            property="twitter:description"
            content={singlePost.data.fields.description}
          />
          <meta
            property="twitter:image"
            content={singlePost.data.fields.heroImage.fields.file.url}
          />

          {/*           
          <link
            rel="canonical"
            href={`https://notifime.app/blog/${singlePost.data.fields.slug}`}
          ></link>
          
          <meta property="og:title" content={singlePost.data.fields.title} />
          <meta
            property="og:description"
            content={singlePost.data.fields.description}
          />
          <meta
            property="og:url"
            content={`https://notifime.app/blog/${singlePost.data.fields.slug}`}
          />
          <meta
            property="og:image"
            content={singlePost.data.fields.heroImage.fields.file.url}
          />
          <meta
            property="article:published_time"
            content={singlePost.data.fields.publishDate}
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:title" content={singlePost.data.fields.title} />
          <meta
            property="twitter:description"
            content={singlePost.data.fields.description}
          />
          <meta
            property="twitter:url"
            content={`https://notifime.app/blog/${singlePost.data.fields.slug}`}
          />
          <meta
            property="twitter:image"
            content={singlePost.data.fields.heroImage.fields.file.url}
          />
          <meta property="twitter:label1" content="Written by" />
          <meta
            property="twitter:data1"
            content={singlePost.data.fields.author.fields.name}
          />
          <meta property="og:image:width" content="956" />
          <meta property="og:image:height" content="697" /> */}
        </Helmet>
        <div className="post-header has-cover">
          <div className="inner">
            <h1 className="post-title">{singlePost.data.fields.title}</h1>
            <span className="post-meta">{`${
              singlePost.data.fields.author.fields.name
            } | ${format(
              new Date(singlePost.data.fields.publishDate),
              "dd MMM yyyy"
            )}`}</span>
            <div
              className="post-cover cover"
              style={{
                backgroundImage: `url(${singlePost.data.fields.heroImage.fields.file.url})`,
                transform: "translate3d(0px, 0px, 0px)",
                opacity: 0.6
              }}
            />
          </div>
        </div>
        <div className="container-lrg">
          <main className="content">
            <div className="inner">
              <section className="post-content">
                <Markdown>{singlePost.data.fields.body}</Markdown>
              </section>
              <section className="post-credits">
                {singlePost.data.fields.heroImage.fields.description ? (
                  <p>
                    <em>
                      Banner Image:{" "}
                      {singlePost.data.fields.heroImage.fields.description}
                    </em>
                  </p>
                ) : null}
              </section>
              <section className="post-info">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="post-tags">
                    {/* {singlePost.data.fields.tags.map(tag => (
                      <Link to={`/tag/${tag}`}>
                        <div className="post-tag">{tag}</div>
                      </Link>
                    ))} */}
                  </div>
                  <div className="post-share">
                    <a
                      className="twitter"
                      target="_blank"
                      href={`https://twitter.com/share?text=${singlePost.data.fields.title}&url=https://notifime.app/blog/${singlePost.data.fields.slug}`}
                    >
                      <Icon type="twitter" />
                    </a>
                    <a
                      className="facebook"
                      target="_blank"
                      href={`https://www.facebook.com/sharer/sharer.php?u=https://notifime.app/blog/${singlePost.data.fields.slug}`}
                    >
                      <FacebookIcon style={{ height: "2em", width: "2em" }} />
                    </a>
                    <a
                      className="reddit"
                      target="_blank"
                      href={`https://www.reddit.com/submit?url=https://notifime.app/blog/${singlePost.data.fields.slug}&title=${singlePost.data.fields.title}`}
                    >
                      <RedditIcon style={{ height: "2em", width: "2em" }} />
                    </a>
                    <a
                      className="hackernews"
                      target="_blank"
                      href={`https://news.ycombinator.com/submitlink?t=${singlePost.data.fields.title}&u=https://notifime.app/blog/${singlePost.data.fields.slug}`}
                    >
                      <YCombinatorIcon
                        style={{ height: "2em", width: "2em" }}
                      />
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ singlePost }) => ({
  singlePost
});

export default connect(
  mapStateToProps,
  { fetchSingleBlogPost }
)(BlogPost);
