import {
  FETCH_BLOG_POSTS_STARTED,
  FETCH_BLOG_POSTS_SUCCESS,
  FETCH_BLOG_POSTS_FAILURE,
  FETCH_SINGLE_BLOG_POST_STARTED,
  FETCH_SINGLE_BLOG_POST_SUCCESS,
  FETCH_SINGLE_BLOG_POST_FAILURE
} from "../actions/types";

const initialState = {
  loading: false,
  data: [],
  error: null
};

export const posts = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BLOG_POSTS_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_BLOG_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_BLOG_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const initialSinglePostState = {
  loading: false,
  data: {},
  error: null
};

export const singlePost = (state = initialSinglePostState, action) => {
  switch (action.type) {
    case FETCH_SINGLE_BLOG_POST_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_SINGLE_BLOG_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_SINGLE_BLOG_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
