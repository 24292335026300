import React from "react";
import matchWholeWordOnlyImg from "../img/match_whole_word_only.png"

const Docs = () => ( 
  <React.Fragment>
    <section className="section">
      <div className="container mb40">
        <div className="col-12 ">
          <h1 className="heading-lrg primary-color text-center ">
            Documentation
          </h1>
          <div className="mt40">
            <h4 className="heading-sml primary-color ">Keywords</h4>
            <div>
              <p className="paragraph mt10 mb10">
                Keywords match on submission titles, URLs, and comment bodies.
                They do not match on usernames.
              </p>
              <p className="paragraph mt10 mb10">
                Special characters will try to match literally, including spaces
                and punctuation.
              </p>
              <p className="paragraph mt10 mb10">
                Matches are <b>not</b> case-sensitive.
              </p>
            </div>
          </div>
          <div className="mt40">
            <h4 className="heading-sml primary-color ">
              Matching Special Symbols
            </h4>
            <div>
              <p className="paragraph mt10 mb10">
                If you enter a match phrase such as `*watch`, then it will
                literally only match when it finds `*watch`. That is, it will
                not match unless an asterisk is found. It will
                <b>not</b> match `wristwatch`, `red watch`, or even just
                `watch`.
              </p>
              <p className="paragraph mt10 mb10">
                This applies to all symbols. If you use commas, spaces, tabs,
                periods, etc, then only expect a match when those symbols are
                actually found.
              </p>
            </div>
          </div>
          <div className="mt40">
            <h4 className="heading-sml primary-color ">Logical Arguments</h4>
            <div>
              <p className="paragraph mt10 mb10">
                If you enter the match phrase `peanut butter and jelly`, then it
                will literally only match the complete phrase `peanut butter and
                jelly`. It will <b>not</b> match `peanut butter jelly`, or
                `peanut butterjelly`, or any other combination.
              </p>
              <p className="paragraph mt10 mb10">
                If you enter the match phrase `peanut butter not jelly`, then it
                will literally only match when it finds `peanut butter not
                jelly`. It will
                <b>not</b> match on `peanut butter`.
              </p>
            </div>
          </div>
          <div className="mt40">
            <h4 className="heading-sml primary-color ">
              Matching Only Whole Words
            </h4>
            <div>
              <div className="mask mask__noimage">
                <img
                  src={matchWholeWordOnlyImg}
                  alt=""
                  className="mask-img"
                />
              </div>
              <p className="paragraph mt10 mb10">
                If "Match whole word only?" is checked, then NotifiMe will only
                match on whole words. The above example matches:
              </p>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div>
                  <h4>Matches</h4>
                  <ul className="fa-ul">
                    <li>
                      <span className="fa-li" style={{ color: "green" }}>
                        <i className="fas fa-check" />
                      </span>
                      Some thing I want.
                    </li>
                    <li>
                      <span className="fa-li" style={{ color: "green" }}>
                        <i className="fas fa-check" />
                      </span>
                      That thing.
                    </li>
                    <li>
                      <span className="fa-li" style={{ color: "green" }}>
                        <i className="fas fa-check" />
                      </span>
                      Thing thing.
                    </li>
                  </ul>
                </div>
                <div>
                  <h4>Does Not Match</h4>
                  <ul className="fa-ul">
                    <li>
                      <span
                        className="fa-li"
                        style={{ color: "mediumvioletred" }}
                      >
                        <i className="fas fa-times" />
                      </span>
                      Something I want.
                    </li>
                    <li>
                      <span
                        className="fa-li"
                        style={{ color: "mediumvioletred" }}
                      >
                        <i className="fas fa-times" />
                      </span>
                      Those things.
                    </li>
                    <li>
                      <span
                        className="fa-li"
                        style={{ color: "mediumvioletred" }}
                      >
                        <i className="fas fa-times" />
                      </span>
                      Thingthing.
                    </li>
                  </ul>
                </div>
              </div>
              <p className="paragraph mt10 mb10">
                If "Whole word only?" is unchecked, then every example above
                matches.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);

export default Docs;
