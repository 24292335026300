import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import BlogPreview from "./Preview";

import { fetchBlogPosts } from "../../actions";

class BlogList extends React.Component {
  componentDidMount() {
    this.props.fetchBlogPosts();
  }
  render() {
    const { posts } = this.props;
    if (posts.loading || !posts.data.length) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="container-lrg" style={{ marginTop: "6em" }}>
          <main className="content">
            {posts.data.map(({ fields, sys }) => (
              <BlogPreview {...fields} key={sys.id} />
            ))}
          </main>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ posts }) => ({
  posts
});

export default connect(
  mapStateToProps,
  { fetchBlogPosts }
)(BlogList);
