import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import { Icon, Popover, Button } from "antd";
import { Helmet } from "react-helmet";

import Landing from "./pages/Landing";
import Docs from "./pages/Docs";
import Support from "./pages/Support";
import Privacy from "./pages/Privacy";
import BrandTracking from "./pages/BrandTracking";
import BlogList from "./pages/Blog/List";
import BlogPost from "./pages/Blog/Post";
import "./styles.css";
import "antd/dist/antd.css";

class App extends Component {
  state = {
    toggle: false,
    hideNav: false
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    let currentHideNav = window.innerWidth < 820;
    this.setState({ hideNav: currentHideNav }, () =>
      console.log("componentDidMount: ", this.state.hideNav)
    );
  }
  handleResize = () => {
    let currentHideNav = window.innerWidth < 820;
    if (this.state.hideNav !== currentHideNav) {
      this.setState({ hideNav: currentHideNav });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleNavClick = () => {
    if (this.state.hideNav) {
      return this.setState({ toggle: !this.state.toggle });
    }
  };

  render() {
    const content = (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Link
          to="/brand-tracking"
          className="nav-link secondary-color"
          onClick={this.handleNavClick}
        >
          Brand Tracking
        </Link>
        <Link
          to="/organic-marketing"
          className="nav-link secondary-color"
          onClick={this.handleNavClick}
        >
          Organic Marketing
        </Link>
        <Link
          to="/competitive-analysis"
          className="nav-link secondary-color"
          onClick={this.handleNavClick}
        >
          Competitive Analysis
        </Link>
        <Link
          to="/crisis-management"
          className="nav-link secondary-color"
          onClick={this.handleNavClick}
        >
          Crisis Management
        </Link>
      </div>
    );

    return (
      <BrowserRouter>
        <Helmet>
          <title>NotifiMe</title>
          {/* <!-- Primary Meta Tags --> */}
          <meta name="title" content="NotifiMe" />
          <meta
            name="description"
            content="Social customer care software for support and marketing teams"
          />

          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://notifime.app/" />
          <meta property="og:title" content="NotifiMe" />
          <meta
            property="og:description"
            content="Social customer care software for support and marketing teams"
          />
          <meta
            property="og:image"
            content="https://notifime.app/social-share.png"
          />

          {/* <!-- Twitter --> */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://notifime.app/" />
          <meta property="twitter:title" content="NotifiMe" />
          <meta
            property="twitter:description"
            content="Social customer care software for support and marketing teams"
          />
          <meta
            property="twitter:image"
            content="https://notifime.app/social-share.png"
          />
        </Helmet>
        <div className="font-neutral">
          <div className="black-white">
            <header className="header">
              <div className="container-lrg">
                <div className="flex col-12 spread">
                  <Link
                    to="/"
                    style={{ cursort: "pointer" }}
                    className="logo primary-color  logo"
                  >
                    NotifiMe
                  </Link>

                  {this.state.hideNav && !this.state.toggle ? (
                    <div
                      className="hamburger-menu"
                      onClick={() =>
                        this.setState({ toggle: !this.state.toggle })
                      }
                    >
                      <b
                        className="mr0 secondary-color"
                        style={{
                          width: "100%",
                          textSlign: "center",
                          margin: 0,
                          fontSize: "calc(120% + 1vw)",
                          color: "#000"
                        }}
                      >
                        ☰
                      </b>
                    </div>
                  ) : (
                    <div className="hamburger-menu" />
                  )}
                  {!this.state.hideNav || this.state.toggle ? (
                    <div className="header-navbar">
                      <div className="close" onClick={this.handleNavClick}>
                        <span className="bold">✕</span>
                      </div>
                      {/* <div
                        className="nav-link secondary-color"
                        style={{ cursor: "pointer", marginRight: "15px" }}
                      >
                        <Popover
                          content={content}
                          trigger="click"
                          placement={"bottom"}
                        >
                          Use Cases <Icon type="caret-down" />
                        </Popover>
                      </div> */}
                      <Link
                        to="/blog"
                        className="nav-link secondary-color"
                        onClick={this.handleNavClick}
                      >
                        Blog
                      </Link>
                      <Link
                        to="/docs"
                        className="nav-link secondary-color"
                        onClick={this.handleNavClick}
                      >
                        Docs
                      </Link>
                      <a
                        href="https://dashboard.notifime.app/login"
                        className="nav-link secondary-color"
                        onClick={this.handleNavClick}
                      >
                        Login
                      </a>
                      <a
                        href="https://dashboard.notifime.app/register"
                        className="nav-link secondary-color"
                        onClick={this.handleNavClick}
                      >
                        Signup{" "}
                        <Icon
                          style={{ verticalAlign: "sub" }}
                          type="arrow-right"
                        />
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            </header>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/docs" component={Docs} />
              <Route exact path="/support" component={Support} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/brand-tracking" component={BrandTracking} />
              <Route exact path="/blog" component={BlogList} />
              <Route exact path="/blog/:id" component={BlogPost} />;
            </Switch>
          </div>
          <footer
            id="footer-5"
            className="section text-center black-white"
            activepage="Landing"
          >
            <div className="container col-12">
              <nav className="mb50">
                <Link to="/blog" className="nav-link secondary-color">
                  Blog
                </Link>
                <Link to="/docs" className="nav-link secondary-color">
                  Docs
                </Link>
                <Link to="/privacy" className="nav-link secondary-color">
                  Privacy
                </Link>
                <Link to="/support" className="nav-link secondary-color">
                  Support
                </Link>
              </nav>
              <div className="mt50">
                <span className="span secondary-color">©</span>
                <span className="span secondary-color">NotifiMe 2019</span>
              </div>
            </div>
          </footer>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
