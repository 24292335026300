import React from "react";
import RequestDemo from "../components/RequestDemo";
import slackWorkspaceImg from "../img/slack_workspace2.png";

const BrandTracking = () => (
  <React.Fragment>
    <div className="black-white">
      <section className="section">
        <div className="container mb40">
          <div className="col-12 text-center">
            <h1 className="heading-lrg primary-color launchaco-builder-hoverable">
              Track your brand on Reddit.
            </h1>
            <h2 className="subheading secondary-color mt20 launchaco-builder-hoverable">
              Find out how your brand is perceived online in realtime.
            </h2>
            <div className="mt40">
              <a
                href="https://dashboard.notifime.app/register"
                target="_blank"
                rel="noopener noreferrer"
                className="button mobile-text-center mt10 launchaco-builder-hoverable mobile-text-center accent-bg primary-color"
              >
                <span>Start A Free 7-Day Trial</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section
      id="feature-1"
      className="section black-white"
      activepage="Landing"
    >
      <div className="container-lrg flex">
        <div className="col-4 text-center flex flex-column center-horizontal">
          <i className="icon mobile-center-icon secondary-bg launchaco-builder-hoverable">
            <svg
              className="svg-fill"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25 25"
            >
              <path d="M10 4a3 3 0 1 1 3 3 3 3 0 0 1-3-3zm10 2a3 3 0 1 0-3-3 3 3 0 0 0 3 3zM6 6a3 3 0 1 0-3-3 3 3 0 0 0 3 3zm1 10.5V13a5.987 5.987 0 0 1 2.31-4.721A4.979 4.979 0 0 0 1 12v2.5A1.5 1.5 0 0 0 2.5 16H3v4.5A1.5 1.5 0 0 0 4.5 22h3A1.5 1.5 0 0 0 9 20.5v-1.55a2.505 2.505 0 0 1-2-2.45zM20 7a4.96 4.96 0 0 0-3.31 1.279A5.987 5.987 0 0 1 19 13v3.5a2.505 2.505 0 0 1-2 2.45v1.55a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V16h.5a1.5 1.5 0 0 0 1.5-1.5V12a5 5 0 0 0-5-5zm-2 9.5V13a5 5 0 1 0-10 0v3.5A1.5 1.5 0 0 0 9.5 18h.5v5.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V18h.5a1.5 1.5 0 0 0 1.5-1.5z" />
            </svg>
          </i>
          <h3 className="bold primary-color launchaco-builder-hoverable">
            Word of Mouth
          </h3>
          <p className="paragraph secondary-color launchaco-builder-hoverable">
            Proactively address concerns and objections in realtime. Show
            potential user's your amazing customer service.
          </p>
        </div>
        <div className="col-4 text-center flex flex-column center-horizontal">
          <i className="icon secondary-bg mr20 mobile-center-icon launchaco-builder-hoverable">
            <svg
              className="svg-fill"
              viewBox="0 0 25 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M14.001 16.131l4 3h-7.335l-5.066 3.8a1 1 0 0 1-1.6-.8v-3H1.5a1.5 1.5 0 0 1-1.5-1.5V5.633a1.5 1.5 0 0 1 1.5-1.5H3v9.498a2.502 2.502 0 0 0 2.5 2.5h8.501zM25 1.633v11.998a1.5 1.5 0 0 1-1.5 1.5H21v3a1 1 0 0 1-1.6.8l-5.066-3.8H5.5a1.5 1.5 0 0 1-1.5-1.5V1.633a1.5 1.5 0 0 1 1.5-1.5h18a1.5 1.5 0 0 1 1.5 1.5zm-5 7.998a.5.5 0 0 0-.5-.5h-10a.5.5 0 0 0 0 1h10a.5.5 0 0 0 .5-.5zm0-4a.5.5 0 0 0-.5-.5h-10a.5.5 0 0 0 0 1h10a.5.5 0 0 0 .5-.5z" />
            </svg>
          </i>
          <h3 className="bold primary-color launchaco-builder-hoverable">
            Relevant Conversations
          </h3>
          <p className="paragraph secondary-color launchaco-builder-hoverable">
            Find and filter social conversations by keyword to hear what people
            are saying about your brand, competitors, and industry.
          </p>
        </div>
        <div className="col-4 text-center flex flex-column center-horizontal">
          <i className="icon mobile-center-icon secondary-bg launchaco-builder-hoverable">
            <svg
              className="svg-fill"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25 25"
            >
              <path d="M24 20.5a2.5 2.5 0 1 1-3-2.45V13.5a.5.5 0 0 0-.5-.5H13v5.05a2.5 2.5 0 1 1-1 0V13H4.5a.5.5 0 0 0-.5.5v4.55a2.5 2.5 0 1 1-1 0V13.5A1.5 1.5 0 0 1 4.5 12H12V6.95a2.5 2.5 0 1 1 1 0V12h7.5a1.5 1.5 0 0 1 1.5 1.5v4.55a2.5 2.5 0 0 1 2 2.45z" />
            </svg>
          </i>
          <h3 className="bold primary-color launchaco-builder-hoverable">
            Easy Integrations
          </h3>
          <p className="paragraph secondary-color launchaco-builder-hoverable">
            One click integration with Slack. Get notified on Slack when your
            selected keywords are mentioned on Reddit.
          </p>
        </div>
      </div>
      <div className="container-lrg">
        <div className="col-12">
          <div className="browser mt75 launchaco-builder-hoverable">
            <div className="mask mask__noimage">
              <img src={slackWorkspaceImg} alt="" className="mask-img" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="how-it-works"
      className="section black-white"
      activepage="Landing"
    >
      <div className="container mb75 text-center">
        <div className="col-12">
          <h4 className="heading primary-color launchaco-builder-hoverable">
            How it works.
          </h4>
          <p className="paragraph secondary-color launchaco-builder-hoverable">
            It's really simple.
          </p>
          <div
            className="col-12"
            style={{ paddingTop: "20px", textAlign: "left" }}
          >
            <ol className="bold primary-color mr20">
              <li>
                <a
                  className="link-accent"
                  href="https://dashboard.notifime.app/register"
                >
                  Signup
                </a>
                {" for an account"}.
              </li>
              <li>Enter a list of keywords that you want to monitor.</li>
              <li>Connect with your Slack account.</li>
              <li>
                NotifiMe will send you a message whenever one of your keywords
                is mentioned on Reddit.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section
      id="faq-3"
      className="section black-white"
      sitemeta="[object Object]"
      activepage="Landing"
    >
      <div className="container text-center">
        <div className="col-12">
          <h3 className="heading primary-color launchaco-builder-hoverable">
            Frequently Asked Questions
          </h3>
          <p className="subheading secondary-color mt20 launchaco-builder-hoverable">
            Can't find an answer? Don't hesitate to reach out!.
          </p>
        </div>
      </div>
      <div className="container mt40">
        <div className="col-12">
          <div className="faq pad20">
            <input
              id="faq-dropdown-3-0"
              type="checkbox"
              name="faq"
              className="faq-input"
            />
            <label
              htmlFor="faq-dropdown-3-0"
              className="faq-label flex spread center-horizontal"
            >
              <span className="bold primary-color mr20">
                What sites are monitored?
              </span>
              <svg
                width="15px"
                height="9px"
                viewBox="0 0 15 9"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className="noshrink svg-fill"
              >
                <path d="M7.4176407,5.65342711 L1.76421359,8.8817842e-16 L0.350000024,1.41421356 L6.00342714,7.06764068 L6,7.07106781 L7.41421356,8.48528137 L7.4176407,8.48185424 L7.42106784,8.48528137 L8.8352814,7.07106781 L8.83185426,7.06764068 L14.4852814,1.41421356 L13.0710678,8.8817842e-16 L7.4176407,5.65342711 Z" />
              </svg>
            </label>
            <p className="paragraph secondary-color faq-content mt20 launchaco-builder-hoverable">
              {"NotifiMe currently monitors "}
              <a className="link-accent" href="https://reddit.com">
                Reddit
              </a>
              .
            </p>
          </div>
          <div className="faq pad20">
            <input
              id="faq-dropdown-3-1"
              type="checkbox"
              name="faq"
              className="faq-input"
            />
            <label
              htmlFor="faq-dropdown-3-1"
              className="faq-label flex spread center-horizontal"
            >
              <span className="bold primary-color mr20">
                Does it look at comments too?
              </span>
              <svg
                width="15px"
                height="9px"
                viewBox="0 0 15 9"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className="noshrink svg-fill"
              >
                <path d="M7.4176407,5.65342711 L1.76421359,8.8817842e-16 L0.350000024,1.41421356 L6.00342714,7.06764068 L6,7.07106781 L7.41421356,8.48528137 L7.4176407,8.48185424 L7.42106784,8.48528137 L8.8352814,7.07106781 L8.83185426,7.06764068 L14.4852814,1.41421356 L13.0710678,8.8817842e-16 L7.4176407,5.65342711 Z" />
              </svg>
            </label>
            <p className="paragraph secondary-color faq-content mt20 launchaco-builder-hoverable">
              {"Yes. NotifiMe scans both submission titles and comments. See "}
              <a className="link-accent" href="./docs.html">
                here
              </a>{" "}
              for more info.
            </p>
          </div>
          <div className="faq pad20">
            <input
              id="faq-dropdown-3-2"
              type="checkbox"
              name="faq"
              className="faq-input"
            />
            <label
              htmlFor="faq-dropdown-3-2"
              className="faq-label flex spread center-horizontal"
            >
              <span className="bold primary-color mr20">
                Are keywords/phrases case-sensitive?
              </span>
              <svg
                width="15px"
                height="9px"
                viewBox="0 0 15 9"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className="noshrink svg-fill"
              >
                <path d="M7.4176407,5.65342711 L1.76421359,8.8817842e-16 L0.350000024,1.41421356 L6.00342714,7.06764068 L6,7.07106781 L7.41421356,8.48528137 L7.4176407,8.48185424 L7.42106784,8.48528137 L8.8352814,7.07106781 L8.83185426,7.06764068 L14.4852814,1.41421356 L13.0710678,8.8817842e-16 L7.4176407,5.65342711 Z" />
              </svg>
            </label>
            <p className="paragraph secondary-color faq-content mt20 launchaco-builder-hoverable">
              No. Keywords and phrases are not case-sensitive. They will be
              matched regardless of capitalization.
            </p>
          </div>
          <div className="faq pad20">
            <input
              id="faq-dropdown-3-3"
              type="checkbox"
              name="faq"
              className="faq-input"
            />
            <label
              htmlFor="faq-dropdown-3-3"
              className="faq-label flex spread center-horizontal"
            >
              <span className="bold primary-color mr20">
                Can it let me know when my website is submitted?
              </span>
              <svg
                width="15px"
                height="9px"
                viewBox="0 0 15 9"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className="noshrink svg-fill"
              >
                <path d="M7.4176407,5.65342711 L1.76421359,8.8817842e-16 L0.350000024,1.41421356 L6.00342714,7.06764068 L6,7.07106781 L7.41421356,8.48528137 L7.4176407,8.48185424 L7.42106784,8.48528137 L8.8352814,7.07106781 L8.83185426,7.06764068 L14.4852814,1.41421356 L13.0710678,8.8817842e-16 L7.4176407,5.65342711 Z" />
              </svg>
            </label>
            <p className="paragraph secondary-color faq-content mt20 launchaco-builder-hoverable">
              Yes. You can enter a partial URL as a keyword to track. Make sure
              the "track whole word only" box is unchecked.
            </p>
          </div>
          <div className="faq pad20">
            <input
              id="faq-dropdown-3-4"
              type="checkbox"
              name="faq"
              className="faq-input"
            />
            <label
              htmlFor="faq-dropdown-3-4"
              className="faq-label flex spread center-horizontal"
            >
              <span className="bold primary-color mr20">
                How can I test that it works?
              </span>
              <svg
                width="15px"
                height="9px"
                viewBox="0 0 15 9"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className="noshrink svg-fill"
              >
                <path d="M7.4176407,5.65342711 L1.76421359,8.8817842e-16 L0.350000024,1.41421356 L6.00342714,7.06764068 L6,7.07106781 L7.41421356,8.48528137 L7.4176407,8.48185424 L7.42106784,8.48528137 L8.8352814,7.07106781 L8.83185426,7.06764068 L14.4852814,1.41421356 L13.0710678,8.8817842e-16 L7.4176407,5.65342711 Z" />
              </svg>
            </label>
            <p className="paragraph secondary-color faq-content mt20 launchaco-builder-hoverable">
              {"You can post your keyword or phrase on Reddit in the "}
              <a className="link-accent" href="https://reddit.com/r/test">
                test subreddit
              </a>
              . You should expect a Slack notification from NotifiMe within a
              couple minutes after you do.
            </p>
          </div>
          <div className="faq pad20">
            <input
              id="faq-dropdown-3-5"
              type="checkbox"
              name="faq"
              className="faq-input"
            />
            <label
              htmlFor="faq-dropdown-3-5"
              className="faq-label flex spread center-horizontal"
            >
              <span className="bold primary-color mr20">
                Is there a way to try NotifiMe, before I pay?
              </span>
              <svg
                width="15px"
                height="9px"
                viewBox="0 0 15 9"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className="noshrink svg-fill"
              >
                <path d="M7.4176407,5.65342711 L1.76421359,8.8817842e-16 L0.350000024,1.41421356 L6.00342714,7.06764068 L6,7.07106781 L7.41421356,8.48528137 L7.4176407,8.48185424 L7.42106784,8.48528137 L8.8352814,7.07106781 L8.83185426,7.06764068 L14.4852814,1.41421356 L13.0710678,8.8817842e-16 L7.4176407,5.65342711 Z" />
              </svg>
            </label>
            <p className="paragraph secondary-color faq-content mt20 launchaco-builder-hoverable">
              Yep! Anyone can sign up for a free 7-day trial here. This gives
              you plenty of time to find out if NotifiMe is the right tool to
              help you grow your business. We think it will be, of course{" "}
              <span role="img" aria-label="winky face">
                😉
              </span>
              .
            </p>
          </div>
          <div className="faq pad20">
            <input
              id="faq-dropdown-3-6"
              type="checkbox"
              name="faq"
              className="faq-input"
            />
            <label
              htmlFor="faq-dropdown-3-6"
              className="faq-label flex spread center-horizontal"
            >
              <span className="bold primary-color mr20">
                What happens when I outgrow my word limit on my plan?
              </span>
              <svg
                width="15px"
                height="9px"
                viewBox="0 0 15 9"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className="noshrink svg-fill"
              >
                <path d="M7.4176407,5.65342711 L1.76421359,8.8817842e-16 L0.350000024,1.41421356 L6.00342714,7.06764068 L6,7.07106781 L7.41421356,8.48528137 L7.4176407,8.48185424 L7.42106784,8.48528137 L8.8352814,7.07106781 L8.83185426,7.06764068 L14.4852814,1.41421356 L13.0710678,8.8817842e-16 L7.4176407,5.65342711 Z" />
              </svg>
            </label>
            <p className="paragraph secondary-color faq-content mt20 launchaco-builder-hoverable">
              No worries! Change your plan anytime to increase the number of
              words you want to track.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section
      id="cta-4"
      className="section black-white"
      sitemeta="[object Object]"
      activepage="Landing"
    >
      <div className="container text-center">
        <div className="col-12">
          <h4 className="heading-lrg primary-color launchaco-builder-hoverable">
            Don't take our word for it! Try it out yourself!
          </h4>
          <p className="paragraph mt20 secondary-color mt20 launchaco-builder-hoverable" />
          <div className="mt40">
            <a
              href="https://dashboard.notifime.app/register"
              target="_blank"
              rel="noopener noreferrer"
              className="button mobile-text-center mt10 launchaco-builder-hoverable mobile-text-center accent-bg primary-color"
            >
              <span> Start A Free 7-Day Trial </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);

export default BrandTracking;
