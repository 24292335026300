import React from "react";
import { Button, Modal, Form, Input, Select, notification } from "antd";
import axios from "axios";

import {
  countries,
  industries,
  titles,
  revenueRanges,
  employeeRanges
} from "../util/constants";
const Option = Select.Option;

const CollectionCreateForm = Form.create()(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 10 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 }
        }
      };
      return (
        <Modal
          visible={visible}
          title="Request a Demo"
          //   okText="Book your demo now"
          onCancel={onCancel}
          //   onOk={onCreate}
          footer={[
            <Button
              key="submit"
              type="primary"
              style={{ backgroundColor: "#4736ca", height: "44px" }}
              className="font-neutral button mobile-text-center mt10 launchaco-builder-hoverable button__full mobile-text-center accent-bg primary-color"
              onClick={onCreate}
            >
              Book your demo now
            </Button>
          ]}
        >
          <Form {...formItemLayout}>
            <Form.Item label="First Name">
              {getFieldDecorator("firstName", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your first name!"
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Last Name">
              {getFieldDecorator("lastName", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your last name!"
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Business Email Address">
              {getFieldDecorator("businessEmailAddress", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your business email address!"
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Company">
              {getFieldDecorator("company", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your company's name!"
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Number of Employees">
              {getFieldDecorator("numberOfEmployees", {
                rules: [
                  {
                    required: true,
                    message: "Please select the number of employees!"
                  }
                ]
              })(
                <Select placeholder={"- Please Select -"}>
                  {employeeRanges.map(i => (
                    <Option key={i} value={i}>{i}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Industry">
              {getFieldDecorator("industry", {
                rules: [
                  {
                    required: true,
                    message: "Please select your industry!"
                  }
                ]
              })(
                <Select placeholder={"- Please Select -"}>
                  {industries.map(i => (
                    <Option key={i} value={i}>{i}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Country">
              {getFieldDecorator("country", {
                rules: [
                  {
                    required: true,
                    message: "Please select your country!"
                  }
                ]
              })(
                <Select placeholder={"- Please Select -"}>
                  {countries.map(i => (
                    <Option key={i} value={i}>{i}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Phone Number">
              {getFieldDecorator("phoneNumber", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your phone number!"
                  }
                ]
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Title">
              {getFieldDecorator("title", {
                rules: [
                  {
                    required: true,
                    message: "Please select your country!"
                  }
                ]
              })(
                <Select placeholder={"- Please Select -"}>
                  {titles.map(i => (
                    <Option key={i} value={i}>{i}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Company Revenue">
              {getFieldDecorator("companyRevenue", {
                rules: [
                  {
                    required: true,
                    message: "Please select your company's revenue range!"
                  }
                ]
              })(
                <Select placeholder={"- Please Select -"}>
                  {revenueRanges.map(i => (
                    <Option key={i} value={i}>{i}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class RequestDemo extends React.Component {
  state = {
    visible: false
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    const form = this.formRef.props.form;
    this.setState({ visible: false }, () => form.resetFields());
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields(async (err, values) => {
      try {
        if (err) {
          return;
        }
        console.log(
          "Received values of form: ",
          values,
          process.env.REACT_APP_API_BASE_URL
        );
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/leads/requestdemo`,
          {
            ...values
          },
          { withCredentials: true }
        );
        let { success } = res.data;
        if (success) {
          this.openSuccessNotification();
          form.resetFields();
          this.setState({ visible: false });
        }
      } catch (error) {
        this.openErrorNotification();
        console.log(error);
      }
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  openSuccessNotification = () => {
    notification["success"]({
      message: "Thanks for submitting your information!",
      description: "We will reach out to you within 1 business day.",
      duration: 0
    });
  };

  openErrorNotification = () => {
    notification["error"]({
      message: "Something went wrong!",
      description:
        "Please send us an email to support@notifime.app with your contact information and we will reach out to you within 1 business day.",
      duration: 0
    });
  };

  render() {
    return (
      <div>
        <div
          onClick={this.showModal}
          className="button mobile-text-center mt10 launchaco-builder-hoverable button__full mobile-text-center accent-bg primary-color"
        >
          <span>Request A Demo</span>
        </div>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
        />
      </div>
    );
  }
}

export default RequestDemo;
