import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const BlogPreview = ({
  title,
  slug,
  contentPreview,
  author,
  publishDate
}) => (
  <article className="post">
    <div className="inner">
      <header className="post-header">
        <Link to={`/blog/${slug}`}>
          <h2 className="post-title">{title}</h2>
        </Link>
        <span className="post-meta">
          {`${author.fields.name} | ${format(
            new Date(publishDate),
            "dd MMM yyyy"
          )}`}
        </span>
      </header>
      <section className="post-excerpt">
        <p>{contentPreview}</p>
      </section>
    </div>
  </article>
);

export default BlogPreview;
